/* src/components/Contact.css */

.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 20px; /* Increased padding for more white space */
  text-align: left;
}

.contact-container h1 {
  font-size: 2.3rem;
  font-weight: bold;
  margin-bottom: 2rem; /* Increased margin for more space below the heading */
}

.contact-container p {
  font-size: 1rem;
  line-height: 1.8; /* Slightly increased line height for better readability */
  margin-bottom: 2rem; /* Increased margin for more space between paragraphs */
}

.contact-button {
  padding: 20px 40px; /* Increased padding for a larger button */
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  border-radius: 4px;
  text-transform: uppercase;
  margin: 30px 0; /* Increased margin around the button */
  transition: background-color 0.3s;
  text-decoration: none; /* Ensures the link appears as a button */
  display: inline-block;
}

.contact-button:hover {
  background-color: #333;
}
