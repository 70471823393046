/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  font-family: 'Arial', sans-serif;
  position: relative;
  z-index: 1000;
}

.navbar-logo img {
  height: 25px;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001; /* Higher z-index for visibility */
}

.hamburger-line {
  width: 25px;
  height: 2px;
  background-color: black;
  margin: 4px 0;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-right: 20px;
}

.navbar-links li a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  padding: 5px 0;
  position: relative;
}

.navbar-links li a:hover,
.navbar-links li a:focus {
  color: black;
}

.navbar-links li a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: black;
  transition: width 0.3s;
  position: absolute;
  left: 0;
  bottom: -2px;
}

.navbar-links li a:hover::after,
.navbar-links li a:focus::after {
  width: 100%;
}

/* Responsive Styles */
@media (max-width: 800px) {
  .navbar {
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
  }

  .navbar-logo {
    margin-bottom: 10px;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh; /* Ensures full height */
    width: 100vw; /* Ensures full width */
    background-color: white;
    padding: 40px 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Higher z-index to keep it on top */
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%); /* Start off-screen */
  }

  .navbar-links.open {
    transform: translateX(0); /* Slide into view */
  }

  .hamburger {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .navbar-links li {
    margin: 10px 0;
  }
}
